import { graphql, Link } from 'gatsby';
import React from 'react';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';
import Breadcrumb from '../components/Breadcrumb';
// import { withArticles } from '../utils';

const Section = (props) => {
  const { /* categories, */ category } = props.data;
  // const categoriesForSidebar = categories.nodes.filter(withArticles);

  return (
    <Layout /* withSearch={true} */>
      <Seo
        title={category.name}
        description={category.description}
        lang={category.locale}
        path={`/help/${category.slug}`}
      />

      <div className="px-4 sm:px-6 mx-auto max-w-5xl">
        <Breadcrumb
          paths={[
            { url: '/help', name: 'All categories' },
            { name: category.name },
          ]}
        />

        <div className="">
          <div>
            <h1>{category.name}</h1>
            <p className="text-lg text-gray-500">{category.description}</p>
          </div>

          <ul>
            {category.articles.map((article) => (
              <li
                key={article.slug}
                className="py-4 border-t last:border-b border-gray-300"
              >
                <Link to={`/help/${category.slug}/${article.slug}/`}>
                  {article.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Section;

export const query = graphql`
  query SectionData($id: String) {
    categories: allContentfulKbAppCategory {
      nodes {
        name
        slug
        articles: kbapparticle {
          id
        }
      }
    }
    category: contentfulKbAppCategory(id: { eq: $id }) {
      name
      description: previewDescription
      slug
      articles: kbapparticle {
        title
        slug
      }
      locale: node_locale
    }
  }
`;
